
import { Mixins, Component, PropSync, Prop, Watch } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import ETable from "@/components/ETable.vue";

import { healthRecordPlanDetail } from "@/api/healthRecord";
import { format } from "path";

@Component({
    components: { ETable }
})
export default class PlanDetailList extends Mixins(loading,tablePagination,Resize) {
    @PropSync("visible") syncVisible !: boolean;
    @Prop() planRecord !: any;

    itemTotal = 0;
    tableData = [];

    columns = [
        { label: "姓名",prop: "name"},
        { label: "性别",prop: "gender"},
        { label: "状态",prop: "status" },
        { label: "分值",prop: "medicineTotal" },
        { label: "诊断结果",prop: "result" },
        { label: "检测时间",prop: "createTime"},
    ]

    formatterStatus(row) {
        return row.name
    }

    handleClose() {
        this.syncVisible = false;
    }

    @Watch("visible", { immediate: true })
    getData() {
        if (!this.planRecord || !this.planRecord.id || !this.syncVisible) return;
        healthRecordPlanDetail({ planId: this.planRecord.id }).then((res) => {
            this.tableData = res.data;
        })
    }
}
