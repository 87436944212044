import { service } from '@/ajax/request'

export const healthRecordGroupList = (params) => {
    return service({
        url: `/healthRecords/healthRecordsGroup/queryGroup`,
        method: 'POST',
        params,
    })
}

export const healthRecordPlanList = (params) => {
    return service({
        url: `/healthRecords/healthRecordsPlan/detectionPlan`,
        method: 'POST',
        params,
    })
}

export const healthRecordPlanDetail = (params) => {
    return service({
        url: `/healthRecords/healthRecordsPlan/userDetectionDetail`,
        method: 'POST',
        params,
    })
}

export const healthRecordGroupDetail = (params) => {
    return service({
        url: `/healthRecords/healthRecordsGroup/getGroupDetail`,
        method: 'POST',
        params,
    })
}

export const groupDetailMember = (params) => {
    return service({
        url: `/healthRecords/healthRecordsGroup/groupMembersInfo`,
        method: 'POST',
        params,
    })
}

export const groupDetailMemberDetection = (params) => {
    return service({
        url: `/healthRecords/healthRecordsGroup/groupDetectionDetails`,
        method: 'POST',
        params,
    })
}