
  import { Ref, Mixins, Component, InjectReactive, Watch } from "vue-property-decorator";
  import tablePagination from "@/mixins/tablePagination";
  import loading from "@/mixins/loading";
  import Resize from "@/mixins/resize";
  
  import ETable from "@/components/ETable.vue";
  import RichText from "@/components/rich-text.vue";
  import { healthRecordGroupList } from "@/api/healthRecord";
  
  import { Form } from "element-ui";
  
  @Component({
    components: {ETable,RichText}
  })
  export default class HealthRecordGroup extends Mixins(loading, tablePagination, Resize) {
      @InjectReactive() currentTab !: number;
      @Ref("ruleForm") ruleFormRef !: Form;
      healthNameOrShowName = "";

      queryForm = {
        groupType: "",
        groupName: "",
        storeName: "",
        contactName: "",
      };
  
      itemTotal = 0;
      tableData = [];
  
      columns = [
          { label: "ID", prop: "id" },
          { label: "小组类型", prop: "groupTypeDesc" },
          { label: "小组名称", prop: "groupName" },
          { label: "负责人", prop: "contactName" },
          { label: "人数", prop: "totalPersons" },
          { label: "关联门店", prop: "storeName" },
          { label: "创建人", prop: "createdBy" },
          { label: "创建时间", prop: "createdTime" },
          { label: "操作", prop: "action" },
      ]
  
      groupTypeOptions = [
          { label: "幼儿园", value: 1 },
          { label: "小学", value: 2 },
          { label: "养老院", value: 3 },
          { label: "其他", value: 4 },
      ]
  
      mounted() {
        this.windowResize(320);
      }
  
      searchData() {
          this.resetCurrent();
          this.getData();
      }
  
      @Watch("currentTab")
      getData() {
        if (this.currentTab != 2) return;
        const params = {
            pageNum: this.currentPage,
            pageSize: this.pageSize,
            ...this.queryForm,
        }
        this.showLoading();
        
        healthRecordGroupList(params).then((res) => {
            res.data.list.forEach((el) => {
                el.groupTypeDesc = this.groupTypeOptions.find(item => item.value == el.groupType).label;
            })
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        })
      }  

      goDetail(row) {
        this.$router.push({
            path: "/healthRecordDetail",
            query: {
                id: row.id
            }
        })
      }
  }
  
  