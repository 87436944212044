
import { Watch, Mixins, Component, ProvideReactive } from "vue-property-decorator";

import Group from "./components/group.vue";
import Plan from "./components/plan.vue"

@Component({
  inject: [],
  components: { Group, Plan }
})
export default class HealthRecordIndex extends Mixins() {
    @ProvideReactive() currentTab = "1";
}

